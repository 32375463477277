import React from 'react';
import { Link } from 'react-router-dom';

function Campaigns1() {
  return (
    <>
      <section id="common_banner_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="commn_banner_page">
                <h2>Campaigns</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="trending_causes_main" className="section_padding">
        <div className="container">
          <div className="row" id="counter">
            <div className="col-lg-8">
              <div className="details_wrapper_area">
                <div className="details_big_img">
                  <img src="assets/img/new/campaigns1.webp" alt="img" />
                </div>
                <div className="details_text_wrapper">
                  <h2>These Cows Have Suffered Through The Worst, Help Dharam Raj Ranka Give Them A Healthy Life</h2>
                  <p>
                    We rely on our Gau Mata, the humble cow, to provide us with nutritious milk. We ensure that our children drink their milk and are healthy from it. We make sweets that are tasty, rich and flavorful with their milk. But today, because of the Corona crisis, our Gau Mata is suffering. The Corona lockdown has hurt the lives of so many innocent humans and animals.
                  </p>
                  <br />
                  <iframe width="100%" height="500" src="https://www.youtube.com/embed/ai5BtVrHlKg?si=It2KgvyvVc_vkd4i" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>


                  <img src='assets/img/new/campaigns1a.webp' style={{ width: "100%" }} alt='danveer' />
                  <img src='assets/img/new/campaigns1b.webp' style={{ width: "100%" }} alt='danveer' />

                  <p>
                    Give these animals a way to overcome the crisis by donating fodder.
                  </p>
                  <h3>About The NGO:</h3>
                  <p>
                    Satyam Dietym Sundaram - Satyam Dietym Sundaram Gau Niwas is a shelter for rescued cows, founded by Mr. Dharam Raj Ranka, a city jeweller from Hyderabad. Since 1991, it has been his mission to protect cows from illegal slaughter houses, and he has rescued and rehabilitated over 6000 cows, in his shelter so far.  This cow shelter aims to provide a happy life to the rescued cows. They are well looked after, given due medical attention and fodder. The shelter even has a working staff of over 100 people & 2 resident veterinarians to look after these innocent animals.
                  </p>

                </div>

              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar_first">
                {/* Project Organizer */}
                <div className="project_organizer_wrapper sidebar_boxed">
                  <div className="project_organizer_text">
                    <h4>These Cows Have Suffered Through The Worst, Help Dharam Raj Ranka Give Them A Healthy Life</h4>
                    <p>By Satyam Gaushala</p><br />

                    <div className='text-center'>
                      <Link to="/donation" className="btn btn_theme btn_md">Donate Now</Link>
                    </div>
                  </div>
                </div>

                {/* Recent Donet */}
                <div className="project_recentdonet_wrapper sidebar_boxed">
                  <div className="sidebar_heading_main">
                    <h3>Recent donations</h3>
                  </div>
                  <div className="recent_donet_item">
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4>Rohit Kaushik
                        </h4>
                        <h5>Rs. 5000.00</h5>
                      </div>
                      <p>Jaipur</p>
                    </div>
                  </div>

                  <div className="recent_donet_item">
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4>Hruday Gowda
                        </h4>
                        <h5>Rs. 12000.00</h5>
                      </div>
                      <p>Bangalore</p>
                    </div>
                  </div>

                  <div className="recent_donet_item">
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4>Debjeet Bath
                        </h4>
                        <h5>Rs. 9000.00</h5>
                      </div>
                      <p>Raipur</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Campaigns1;
