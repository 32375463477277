import React from 'react';
import { Link } from 'react-router-dom';

function Campaigns3() {
  return (
    <>
    <section id="common_banner_area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="commn_banner_page">
              <h2>Campaigns</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="trending_causes_main" className="section_padding">
      <div className="container">
        <div className="row" id="counter">
          <div className="col-lg-8">
            <div className="details_wrapper_area">
              <div className="details_big_img">
                <img src="assets/img/new/campaigns3.webp" alt="img" />
              </div>
              <div className="details_text_wrapper">
                <h2>Shot By Terrorists But Unstoppable, This Kashmiri Teacher Continues To Educate 200 Children</h2>
                <p>
                “I felt the bullet hit me, and the sharp pain knocked me to the ground. As I lay there, my whole life flashed before my eyes. Was this the end? When I finally opened my eyes, I was in the hospital, surrounded by my family. The fear in their eyes told me something was terribly wrong. The doctor then gave me the news that changed everything—I would never walk again,” recalls Javed Tak, the founder of Zaiba Aapa Institute Of Inclusive Education in Kashmir.</p>
                <br />
                
                <img src='assets/img/new/campaigns3a.webp' style={{ width: "100%" }} alt='danveer' />
                <img src='assets/img/new/campaigns3b.webp' style={{ width: "100%" }} alt='danveer' />
                <img src='assets/img/new/campaigns3c.webp' style={{ width: "100%" }} alt='danveer' />
                <img src='assets/img/new/campaigns3d.webp' style={{ width: "100%" }} alt='danveer' />

                <p>
                Your help can make all the difference in the lives of these incredible children.
                </p>
                <h3>About The NGO:</h3>
                <p>
                Shot by a bullet and left paralysed, Javed Ahmad Tak is now wheelchair-bound. But this hasn’t stopped him from dedicating his life to serving others with disabilities. In 2006, he founded the Zaiba Aapa Institute of Inclusive Education in Kashmir. For years, Javed and his committed team have been tirelessly working to give these special children a life of dignity. 
                </p>
              </div>

            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar_first">
              {/* Project Organizer */}
              <div className="project_organizer_wrapper sidebar_boxed">
                <div className="project_organizer_text">
                  <h4>Shot By Terrorists But Unstoppable, This Kashmiri Teacher Continues To Educate 200 Children</h4>
                  <p>By Danveer Bharat</p><br />

                  <div className='text-center'>
                    <Link to="/donation" className="btn btn_theme btn_md">Donate Now</Link>
                  </div>
                </div>
              </div>

              {/* Recent Donet */}
              <div className="project_recentdonet_wrapper sidebar_boxed">
                <div className="sidebar_heading_main">
                  <h3>Recent donations</h3>
                </div>
                <div className="recent_donet_item">
                  <div className="recent_donet_text">
                    <div className="sidebar_inner_heading">
                      <h4>Rohit Kaushik
                      </h4>
                      <h5>Rs. 5000.00</h5>
                    </div>
                    <p>Jaipur</p>
                  </div>
                </div>

                <div className="recent_donet_item">
                  <div className="recent_donet_text">
                    <div className="sidebar_inner_heading">
                      <h4>Hruday Gowda
                      </h4>
                      <h5>Rs. 12000.00</h5>
                    </div>
                    <p>Bangalore</p>
                  </div>
                </div>

                <div className="recent_donet_item">
                  <div className="recent_donet_text">
                    <div className="sidebar_inner_heading">
                      <h4>Debjeet Bath
                      </h4>
                      <h5>Rs. 9000.00</h5>
                    </div>
                    <p>Raipur</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>
  )
}

export default Campaigns3;
