import React from 'react';

function About() {
  return (
    <>
      <>
        <section id="common_banner_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="commn_banner_page">
                  <h2>About Danveer Bharat</h2>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="about_area" className="section_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="about_area_main_text">
                  <div className="about_area_heading_two">
                    <h2>
                      Together for a <span className="color_big_heading">Better Tomorrow</span>—Empowering Communities, Changing Lives.
                    </h2>
                    <h3>Helping Hands, Healing Hearts.</h3><br />
                    <h4>Out Focus Area</h4>
                  </div>
                  <div className="about_area_para">
                    <h5>Education for All</h5>
                    <p>We aim to bridge the gap in access to education by providing resources and opportunities for children and adults in underprivileged communities. From basic literacy programs to skill development, we empower individuals to improve their lives through learning.
                    </p> <br /><br />
                    <h5>Food for Hunger</h5>
                    <p>
                      No one should go to bed hungry. Our hunger relief programs ensure that vulnerable communities receive nutritious meals, especially during crises and natural disasters. Through community kitchens, food drives, and partnerships, we fight food insecurity one meal at a time.
                    </p> <br /><br />

                    <h5>Animal Welfare</h5>
                    <p>Compassion for animals is at the heart of what we do. We advocate for the protection and well-being of animals by rescuing, rehabilitating, and finding homes for abandoned animals. We also conduct awareness programs to promote humane treatment and the importance of coexisting with animals.</p> <br /><br />

                    <h5>Empowering the Specially-Abled</h5>
                    <p>We are committed to creating an inclusive society where people with disabilities are given the tools and support they need to thrive. Through vocational training, accessibility programs, and advocacy, we help individuals realize their full potential and lead fulfilling lives.</p> <br /><br />

                    <h5>Tree Plantation and Environmental Sustainability</h5>
                    <p>Our planet needs our care, and tree plantation is one of the most effective ways to combat climate change. We organize tree-planting drives in urban and rural areas to improve air quality, combat soil erosion, and create a greener, healthier environment for all.</p> <br /><br />

                  </div>

                  <div className="about_area_heading_two">
                    <h3>Join Us in Making a Difference</h3><br />
                    <p>At Danveer Bharat, we believe that small actions can create lasting change. We invite you to join hands with us as we work towards a world that is just, kind, and sustainable. Whether through volunteering, donations, or spreading awareness, your support helps us continue our mission of making the world a better place for all.
<br/><br/>
Together, we can build a future where no one is left behind.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        
      </>

    </>
  )
}

export default About;
