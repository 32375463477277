import React from 'react';
import { Link } from 'react-router-dom';
function Home() {
    return (
        <>
            <section id="home_two_banner" className='banner-height'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="banner_two_text">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="about_top_area" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_top_boxed bg_one">
                                <div className="about_top_boxed_icon">
                                    <img src="assets/img/icon/book.png" alt="img" />
                                </div>
                                <div className="about_top_boxed_text">
                                    <p>Donate for</p>
                                    <h3>Children education</h3>
                                    {/* <a href="about.html">More details...</a> */}
                                </div>
                                <div className="about_top_boxed_vector">
                                    <img src="assets/img/icon/round.png" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_top_boxed bg_two">
                                <div className="about_top_boxed_icon">
                                    <img src="assets/img/icon/paint.png" alt="img" />
                                </div>
                                <div className="about_top_boxed_text">
                                    <p>Donate for</p>
                                    <h3>Clean mineral water</h3>
                                    {/* <a href="about.html">More details...</a> */}
                                </div>
                                <div className="about_top_boxed_vector">
                                    <img src="assets/img/icon/round.png" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_top_boxed bg_three">
                                <div className="about_top_boxed_icon">
                                    <img src="assets/img/icon/heart.png" alt="img" />
                                </div>
                                <div className="about_top_boxed_text">
                                    <p>Donate for</p>
                                    <h3>Surgery & treatment</h3>
                                    {/* <a href="about.html">More details...</a> */}
                                </div>
                                <div className="about_top_boxed_vector">
                                    <img src="assets/img/icon/round.png" alt="img" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="about_top_boxed bg_four">
                                <div className="about_top_boxed_icon">
                                    <img src="assets/img/icon/restaurant.png" alt="img" />
                                </div>
                                <div className="about_top_boxed_text">
                                    <p>Donate for</p>
                                    <h3>Healthy & good food</h3>
                                    {/* <a href="about.html">More details...</a> */}
                                </div>
                                <div className="about_top_boxed_vector">
                                    <img src="assets/img/icon/round.png" alt="img" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                id="trending_causes_two"
                className="section_after section_padding bg-color"
            >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_two">
                                <h2>Ongoing Campaigns</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row" id="counter">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="case_boxed_wrapper causes_boxed_two">
                                <div className="case_boxed_img">
                                    <Link to="/campaigns1">
                                        <img src="assets/img/new/campaigns1.webp" alt="img" />
                                    </Link>
                                </div>
                                <div className="causes_boxed_text">
                                    <div className="causes_boxed_text_center">
                                        <span className="causes_badge bg-primary"><i className="fa fa-file"></i> Tax Benefit</span> &nbsp;
                                        <span className="causes_badge bg-success"><i className="fa fa-check"></i> Verified</span> &nbsp;
                                        <span className="causes_badge bg-theme"><i className="fa fa-medkit"></i> Medical</span>
                                        <h3 className="cause_heading">
                                            <Link to="/campaigns1">
                                                Severe Infections Will Damage His Kidneys, Your Timely Help Can Save 1.5 YO Ayansh
                                            </Link>
                                        </h3>
                                        <p>By Dr. Zubair Chaoudhary</p>
                                    </div>
                                    <div className="class-full causes_pro_bar progress_bar">
                                        <div className="class-full-bar-box">
                                            <h3 className="h3-title">
                                                Goal: <span> ₹ 1,50,000</span>
                                            </h3>
                                            <div className="class-full-bar-percent">
                                                <h2>
                                                    <span className="counting-data" data-count={25}>
                                                        25
                                                    </span>
                                                    <span>%</span>
                                                </h2>
                                            </div>
                                            <div className="skill-bar class-bar" data-width="25%">
                                                <div className="skill-bar-inner class-bar-in" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/donation" className="btn btn_theme btn_md">Donate Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="case_boxed_wrapper causes_boxed_two">
                                <div className="case_boxed_img">
                                <Link to="/campaigns2">
                                        <img src="assets/img/new/campaigns2.webp" alt="img" />
                                    </Link>
                                </div>
                                <div className="causes_boxed_text">
                                    <div className="causes_boxed_text_center">
                                        <span className="causes_badge bg-primary"><i className="fa fa-file"></i> Tax Benefit</span> &nbsp;
                                        <span className="causes_badge bg-success"><i className="fa fa-check"></i> Verified</span> &nbsp;
                                        <span className="causes_badge bg-theme"><i className="fa fa-medkit"></i> Medical</span>
                                        <h3 className="cause_heading">
                                        <Link to="/campaigns2">
                                            Born Just 11 Days Ago, This Newborn In NICU Needs Your Urgent Help To Survive
                                            </Link>
                                        </h3>
                                        <p>By The Baby Care Foundation (NGO)</p>
                                    </div>
                                    <div className="class-full causes_pro_bar progress_bar">
                                        <div className="class-full-bar-box">
                                            <h3 className="h3-title">
                                                Goal: <span> ₹ 50,000</span>
                                            </h3>
                                            <div className="class-full-bar-percent">
                                                <h2>
                                                    <span className="counting-data" data-count={10}>
                                                        10
                                                    </span>
                                                    <span>%</span>
                                                </h2>
                                            </div>
                                            <div className="skill-bar class-bar" data-width="10%">
                                                <div className="skill-bar-inner class-bar-in" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/donation" className="btn btn_theme btn_md">Donate Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <div className="case_boxed_wrapper causes_boxed_two">
                                <div className="case_boxed_img">
                                <Link to="/campaigns3">
                                        <img src="assets/img/new/campaigns3.webp" alt="img" />
                                    </Link>
                                </div>
                                <div className="causes_boxed_text">
                                    <div className="causes_boxed_text_center">
                                        <span className="causes_badge bg-primary"><i className="fa fa-file"></i> Tax Benefit</span> &nbsp;
                                        <span className="causes_badge bg-success"><i className="fa fa-check"></i> Verified</span> &nbsp;
                                        <span className="causes_badge bg-theme"><i className="fa fa-medkit"></i> Medical</span>
                                        <h3 className="cause_heading">
                                        <Link to="/campaigns3">
                                                Shot By Terrorists But Unstoppable, This Kashmiri Teacher Continues To Educate 200 Children
                                            </Link>
                                        </h3>
                                        <p>By Danveer Bharat Team </p>
                                    </div>
                                    <div className="class-full causes_pro_bar progress_bar">
                                        <div className="class-full-bar-box">
                                            <h3 className="h3-title">
                                                Goal: <span> ₹ 5,00,000</span>
                                            </h3>
                                            <div className="class-full-bar-percent">
                                                <h2>
                                                    <span className="counting-data" data-count={75}>
                                                        75
                                                    </span>
                                                    <span>%</span>
                                                </h2>
                                            </div>
                                            <div className="skill-bar class-bar" data-width="75%">
                                                <div className="skill-bar-inner class-bar-in" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center'>
                                        <Link to="/donation" className="btn btn_theme btn_md">Donate Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="service_area_two" className="section_padding bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="service_two_left_wrapper">
                                <h3>What we do</h3>
                                <h2>Empowering Change: An NGO Transforming Lives Across Communities</h2>
                                <p>
                                At the heart of our NGO’s mission is a deep commitment to uplifting vulnerable communities and the environment through comprehensive, impactful programs. Guided by the principles of compassion and sustainability, our organization works across five key areas: Education, Hunger Relief, Animal Welfare, Support for Specially-Abled Individuals, and Environmental Conservation through Tree Planting.
                                </p>
                                <Link to="/about" className="btn btn_theme btn_md">
                                    More about us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="testimonial_area_three" className="section_padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
                            <div className="section_heading_two">
                                <h3>Our testimonials</h3>
                                <h2>What people are talking</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial_three_boxed">
                                <p>
                                I want to commend your team for the initiative taken with education. The goals and objectives of the project are commendable, and the work being done is much needed. Addressing this could further increase the positive impact of the project.
                                </p>
                                <div className="test_three_bottom">
                                    <div className="test_three_bottom_left">
                                        <h3>Sanjay</h3>
                                        <h6>Alwar</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial_three_boxed">
                                <p>
                                I want to commend your team for the initiative taken with education. The goals and objectives of the project are commendable, and the work being done is much needed. Addressing this could further increase the positive impact of the project.
                                </p>
                                <div className="test_three_bottom">
                                    <div className="test_three_bottom_left">
                                        <h3>Rahul</h3>
                                        <h6>Jaipur</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="testimonial_three_boxed">
                                <p>
                                I want to commend your team for the initiative taken with education. The goals and objectives of the project are commendable, and the work being done is much needed. Addressing this could further increase the positive impact of the project.
                                </p>
                                <div className="test_three_bottom">
                                    <div className="test_three_bottom_left">
                                        <h3>Amit</h3>
                                        <h6>New Delhi</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
        </>

    )
}

export default Home;
