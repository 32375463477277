import React from 'react';
import { Link } from 'react-router-dom';
// import './Footer.css'; // For optional styling

function Footer() {
  return (
    <>
      <section id="subscribe_area">
        <div className="container">
          <div className="subscribe_wrapper">
            <div className="row align-items-center">
              <div className="col-lg-10">
                <div className="subscribe_text">
                  <h3>
                  Join Us in Making a Difference—Your Donation Changes Lives!
                  </h3>
                </div>
              </div>
              <div className="col-lg-2">
                <div className="cta_right_side">
                      <Link to="/donation" className="btn btn_theme btn_md">
                            Donate Now
                        </Link>
                    </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer id="footer_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="footer_area_about">
                <h2>Danveer Bharat</h2>
                <p>
                A non-profit organization dedicated to creating a positive impact across multiple dimensions of society. 
                </p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="footer_navitem_ara">
                <h3>Quick links</h3>
                <div className="nav_item_footer">
                  <ul>
                    <li>
                      <a href="about">About us</a>
                    </li>
                    <li>
                      <a href="campaigns">campaigns</a>
                    </li>
                    <li>
                      <a href="contact">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="footer_navitem_ara">
                <h3>Support</h3>
                <div className="nav_item_footer">
                  <ul>
                    <li>
                      <a href="/">Children education</a>
                    </li>
                    <li>
                      <a href="/">Healthy & good food</a>
                    </li>
                    <li>
                      <a href="/">Surgery & treatment</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="footer_navitem_ara">
                <h3>Contact</h3>
                <div className="footer_area_about">
                <h6>
                  <strong>Address:</strong> First Floor, near Gahlawat Hotel, Surya Nagar, Adooka, Rajasthan 333036
                </h6>
                <h6>
                  <strong>Phone:</strong>{" "}
                  <a href="tel:+917688873768">+91 7688873768</a>
                </h6>
                <h6>
                  <strong>Email:</strong>{" "}
                  <a href="mailto:jeet.jitendra777@gmail.com">jeet.jitendra777@gmail.com</a>
                </h6>
              </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="copyright_area">
        <div className="container">
          <div className="row align-items-center">
            <div className="co-lg-6 col-md-6 col-sm-12 col-12">
              <div className="copyright_left">
                <p>Copyright © 2024 All Rights Reserved</p>
              </div>
            </div>
            <div className="co-lg-6 col-md-6 col-sm-12 col-12">
              <div className="copyright_right">
                <ul>
                  <li>
                    <a href="#!">
                      <i className="fab fa-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-twitter-square" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#!">
                      <i className="fab fa-linkedin" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="go-top">
        <i className="fas fa-chevron-up" />
        <i className="fas fa-chevron-up" />
      </div> */}

    </>
  );
}

export default Footer;
