import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Header from './components/Header'; 
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Donation from './pages/Donation';
import Campaigns from './pages/Campaigns';
import Campaigns1 from './pages/campaigns/campaigns1';
import Campaigns2 from './pages/campaigns/campaigns2';
import Campaigns3 from './pages/campaigns/campaigns3';

function App() {
  return (
    <Router>
        <Header />
        
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/campaigns" element={<Campaigns />} />
            <Route path="/campaigns1" element={<Campaigns1 />} />
            <Route path="/campaigns2" element={<Campaigns2 />} />
            <Route path="/campaigns3" element={<Campaigns3 />} />
            <Route path="/Donation" element={<Donation />} />
          </Routes>
        </main>

        <Footer />
    </Router>
  );
}

export default App;
