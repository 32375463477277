import React from 'react';
import { Link } from 'react-router-dom';

function Campaigns2() {
  return (
    <>
      <section id="common_banner_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="commn_banner_page">
                <h2>Campaigns</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="trending_causes_main" className="section_padding">
        <div className="container">
          <div className="row" id="counter">
            <div className="col-lg-8">
              <div className="details_wrapper_area">
                <div className="details_big_img">
                  <img src="assets/img/new/campaigns2.webp" alt="img" />
                </div>
                <div className="details_text_wrapper">
                  <h2>Help Girish Look After Thousands Of Abandoned Kids And Give Them A Safe Home</h2>
                  <p>
                  We often take for granted things that come to us without any struggle - two square meals a day, a roof over our heads, a loving family etc. Unfortunately, there are thousands in this country who don’t have all those and are forced to take up a path that only pushes them further down the darkness.  </p>
                  <br />
                 
                  <img src='assets/img/new/campaigns2a.webp' style={{ width: "100%" }} alt='danveer' />
                  <img src='assets/img/new/campaigns2b.webp' style={{ width: "100%" }} alt='danveer' />

                  <p>
                  Many of the infants who survive are very fragile and/or premature and require hospitalisation and intensive care. Their adoption process finds loving families for those unwanted and we’re with them every step of the way ensuring a safe and smooth transition to their new home.
                  </p>
                  <h3>About The NGO:</h3>
                  <p>
                  Snehalaya has been working in rural India for almost three decades to rescue, fight for the rights and rehabilitate those adversely affected by the commercial s*x industry, poverty and droughts. They started rescuing trafficked and forced women and children from red light areas in 1989 and have been fighting HIV/AIDS, reducing 2nd generation prostitution by 100% and ensuring there are no minors working in the s*x industry in our district ever since.</p>
                </div>

              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar_first">
                {/* Project Organizer */}
                <div className="project_organizer_wrapper sidebar_boxed">
                  <div className="project_organizer_text">
                    <h4>Help Girish Look After Thousands Of Abandoned Kids And Give Them A Safe Home</h4>
                    <p>By The Baby Care Foundation (NGO)</p><br />

                    <div className='text-center'>
                      <Link to="/donation" className="btn btn_theme btn_md">Donate Now</Link>
                    </div>
                  </div>
                </div>

                {/* Recent Donet */}
                <div className="project_recentdonet_wrapper sidebar_boxed">
                  <div className="sidebar_heading_main">
                    <h3>Recent donations</h3>
                  </div>
                  <div className="recent_donet_item">
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4>Aman Singh
                        </h4>
                        <h5>Rs. 500.00</h5>
                      </div>
                      <p>Jaipur</p>
                    </div>
                  </div>

                  <div className="recent_donet_item">
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4>Pawandeep
                        </h4>
                        <h5>Rs. 12000.00</h5>
                      </div>
                      <p>Bangalore</p>
                    </div>
                  </div>

                  <div className="recent_donet_item">
                    <div className="recent_donet_text">
                      <div className="sidebar_inner_heading">
                        <h4>
                          Lokesh Amar
                        </h4>
                        <h5>Rs. 9000.00</h5>
                      </div>
                      <p>Raipur</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  )
}

export default Campaigns2;
