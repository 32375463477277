import React from 'react';
import { Link } from 'react-router-dom';

function Campaigns() {
  return (
    <>
      <section id="common_banner_area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="commn_banner_page">
                <h2>Campaigns</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id="trending_causes_two"
        className="section_after section_padding bg-color"
      >
        <div className="container">
          <div className="row" id="counter">
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper causes_boxed_two">
                <div className="case_boxed_img">
                  <Link to="/campaigns1">
                    <img src="assets/img/new/campaigns1.webp" alt="img" />
                  </Link>
                </div>
                <div className="causes_boxed_text">
                  <div className="causes_boxed_text_center">
                    <span className="causes_badge bg-primary"><i className="fa fa-file"></i> Tax Benefit</span> &nbsp;
                    <span className="causes_badge bg-success"><i className="fa fa-check"></i> Verified</span> &nbsp;
                    <span className="causes_badge bg-theme"><i className="fa fa-medkit"></i> Medical</span>
                    <h3 className="cause_heading">
                      <Link to="/campaigns1">
                      Help Girish Look After Thousands Of Abandoned Kids And Give Them A Safe Home
                      </Link>
                    </h3>
                    <p>By The Baby Care Foundation (NGO)</p>
                  </div>
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">
                        Goal: <span> ₹ 1,50,000</span>
                      </h3>
                      <div className="class-full-bar-percent">
                        <h2>
                          <span className="counting-data" data-count={25}>
                            25
                          </span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="25%">
                        <div className="skill-bar-inner class-bar-in" />
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <Link to="/campaigns1" className="btn btn_theme btn_md">Donate Now</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper causes_boxed_two">
                <div className="case_boxed_img">
                  <Link to="/campaigns2">
                    <img src="assets/img/new/campaigns2.webp" alt="img" />
                  </Link>
                </div>
                <div className="causes_boxed_text">
                  <div className="causes_boxed_text_center">
                    <span className="causes_badge bg-primary"><i className="fa fa-file"></i> Tax Benefit</span> &nbsp;
                    <span className="causes_badge bg-success"><i className="fa fa-check"></i> Verified</span> &nbsp;
                    <span className="causes_badge bg-theme"><i className="fa fa-medkit"></i> Medical</span>
                    <h3 className="cause_heading">
                      <Link to="/campaigns2">
                      Help Girish Look After Thousands Of Abandoned Kids And Give Them A Safe Home
                      </Link>
                    </h3>
                    <p>By The Baby Care Foundation (NGO)</p>
                  </div>
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">
                        Goal: <span> ₹ 50,000</span>
                      </h3>
                      <div className="class-full-bar-percent">
                        <h2>
                          <span className="counting-data" data-count={10}>
                            10
                          </span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="10%">
                        <div className="skill-bar-inner class-bar-in" />
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <Link to="/campaigns2" className="btn btn_theme btn_md">Donate Now</Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 col-12">
              <div className="case_boxed_wrapper causes_boxed_two">
                <div className="case_boxed_img">
                  <Link to="/campaigns3">
                    <img src="assets/img/new/campaigns3.webp" alt="img" />
                  </Link>
                </div>
                <div className="causes_boxed_text">
                  <div className="causes_boxed_text_center">
                    <span className="causes_badge bg-primary"><i className="fa fa-file"></i> Tax Benefit</span> &nbsp;
                    <span className="causes_badge bg-success"><i className="fa fa-check"></i> Verified</span> &nbsp;
                    <span className="causes_badge bg-theme"><i className="fa fa-medkit"></i> Medical</span>
                    <h3 className="cause_heading">
                      <Link to="/campaigns3">
                        Shot By Terrorists But Unstoppable, This Kashmiri Teacher Continues To Educate 200 Children
                      </Link>
                    </h3>
                    <p>By Danveer Bharat Team </p>
                  </div>
                  <div className="class-full causes_pro_bar progress_bar">
                    <div className="class-full-bar-box">
                      <h3 className="h3-title">
                        Goal: <span> ₹ 5,00,000</span>
                      </h3>
                      <div className="class-full-bar-percent">
                        <h2>
                          <span className="counting-data" data-count={75}>
                            75
                          </span>
                          <span>%</span>
                        </h2>
                      </div>
                      <div className="skill-bar class-bar" data-width="75%">
                        <div className="skill-bar-inner class-bar-in" />
                      </div>
                    </div>
                  </div>
                  <div className='text-center'>
                    <Link to="/campaigns3" className="btn btn_theme btn_md">Donate Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Campaigns;
