import React from 'react';
import { Link } from 'react-router-dom';
// import './Header.css'; // For optional styling

function Header() {
  return (
    <header className="main_header_arae">
        <div className="topbar-area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6">
                        <ul className="topbar-list">

                            <li><Link to="#!"><i className="fa fa-envelope"></i><span>jeet.jitendra777@gmail.com</span></Link>
                            </li>
                            <li><Link to="#!"><i className="fa fa-phone"></i><span>+91 9509641951</span></Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <ul className="topbar-list-right">
                            <li>
                                <Link to="#!"><i className="fab fa-facebook"></i></Link>
                            </li>
                            <li>
                                <Link to="#!"><i className="fab fa-twitter-square"></i></Link>
                            </li>
                            <li>
                                <Link to="#!"><i className="fab fa-instagram"></i></Link>
                            </li>
                            <li>
                                <Link to="#!"><i className="fab fa-linkedin"></i></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div className="navbar-area">
            <div className="main-responsive-nav">
                <div className="container">
                    <div className="main-responsive-menu">
                        <div className="logo">
                            <Link to="/" className="logo_text">
                               <img src="assets/img/new/logo.jpg" alt="logo" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-navbar">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <Link className="navbar-brand logo_text" to="/">
                            <img src="assets/img/new/logo.jpg" alt="logo" />
                        </Link>
                        <div className="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link">Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="about" className="nav-link">About</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="campaigns" className="nav-link">Campaigns</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="contact" className="nav-link">Contact</Link>
                                </li>
                            </ul>
                            <div className="others-options d-flex align-items-center">
                                <div className="option-item">
                                    <Link to="/donation" className="btn btn_navber">Donate now</Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </header>
  );
}

export default Header;
