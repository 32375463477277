import React from 'react';

function Donation() {
  return (
    <>
      <>
        <section id="common_banner_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="commn_banner_page">
                  <h2>Donation</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Area */}
        <section id="contact_arae_main" className="section_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
                <div className="section_heading">
                  <h2>
                    Donate Now
                  </h2>
                  <img src="assets/img/new/qr-code.jpg"  alt="donation"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

    </>
  )
}

export default Donation;
