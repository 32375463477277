import React from 'react';

function Contact() {
  return (
    <>
      <>
        <section id="common_banner_area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="commn_banner_page">
                  <h2>Contact us</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Contact Area */}
        <section id="contact_arae_main" className="section_padding">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
                <div className="section_heading">
                  <h3>Contact with us</h3>
                  <h2>
                    Get in touch with us &amp; stay updates
                  </h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="contact_area_left">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="contact_left_item">
                        <div className="contact_left_icon">
                          <img src="assets/img/icon/map_color.png" alt="icon" />
                        </div>
                        <div className="contact_left_text">
                          <h3>Address:</h3>
                          <p>
                          First Floor, near Gahlawat Hotel, Surya Nagar, Adooka, Rajasthan 333036
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="contact_left_item">
                        <div className="contact_left_icon">
                          <img src="assets/img/icon/email-color.png" alt="icon" />
                        </div>
                        <div className="contact_left_text">
                          <h3>Email:</h3>
                          <a href="mailto:jeet.jitendra777@gmail.com">jeet.jitendra777@gmail.com</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="contact_left_item">
                        <div className="contact_left_icon">
                          <img src="assets/img/icon/phon-color.png" alt="icon" />
                        </div>
                        <div className="contact_left_text">
                          <h3>Phone number:</h3>
                          <a href="tel:+919509641951">+91 9509641951</a>
                        </div>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="contact_full_map" className="section_padding_bottom">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <div className="contact_map_area">
                  <iframe title="googlemap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d28122.89179378522!2d75.65752537862507!3d28.226706219219935!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391320e545702ce3%3A0x168cc1e7ae83a4f8!2sAdooka%2C%20Rajasthan!5e0!3m2!1sen!2sin!4v1727003047292!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>

    </>
  )
}

export default Contact;
